<template>
  <router-view/>
  <cookieBanner/>
</template>
<script>
import cookieBanner from '@/components/cookieBanner.vue'
export default {
  components: {
    cookieBanner
  }
}
</script>
