import { createRouter, createWebHistory } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'

// Create <RouterLayout> component.
const RouterLayout = createRouterLayout(layout => {
  // Resolves a layout component with layout type string.
  return import('@/layouts/' + layout + '.vue')
})

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',

      // Pass <RouterLayout> as the route component
      component: RouterLayout,

      // All child components will be applied with corresponding layout component
      children: [
        {
          path: '',
          component: () => import('@/pages/index.vue')
        },
        {
          path: '/booked/',
          name: 'demo',
          component: () => import('@/pages/demo.vue')
        },
        {
          path: '/book/',
          component: () => import('@/pages/book.vue')
        },
        {
          path: '/cookies/',
          component: () => import('@/pages/cookies.vue')
        },
        {
          path: '/demo/',
          name: 'form',
          component: () => import('@/pages/form.vue')
        },
        {
          path: '/demo/video/',
          name: 'video',
          component: () => import('@/pages/form2.vue'),
          props: { video: true, default: true }
        },
        {
          path: '/demo/tour/:id',
          name: 'form2',
          component: () => import('@/pages/form2.vue')
        },
        {
          path: '/demo/tour/:id/book',
          component: () => import('@/pages/form2.vue'),
          props: { book: true, default: true }
        }
      ]
    }
  ],
  hashbag: true
})
